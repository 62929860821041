import React from "react";
import { isScreensize } from "utils";
import { ProfileMenu } from "./user-menu/user-menu.comp";
import { Link } from "react-router-dom";
import { ROUTES } from "commons/consts";
import styles from "./topbar-comp.module.scss";
import { NotificationPopup } from "./notification-popup/notification-popup.comp";
import { getCompanyFullName } from "utils/config.util";

export const TopBar = () => {
  return (
    <div className={styles.topbar}>
      <div className="mr-auto">
        <img
          style={{ maxHeight: 60 }}
          className="ml-md-0 ml-4"
          src={`${process.env.PUBLIC_URL}/images/${
            isScreensize("sm") ? "logo-notext.png" : "logo.png"
          }`}
          alt={`${getCompanyFullName()} logo`}
        />
      </div>
      <div className="d-flex align-items-center">
        <NotificationPopup />
        <Link to={ROUTES.WALLET_ROUTE}>
          <i className={`fas fa-wallet text-primary ${styles["wallet-icon"]} mx-4`} />
        </Link>
        <ProfileMenu />
      </div>
    </div>
  );
};
