import React from "react";
import styles from "./footer-comp.module.scss";
import { ROUTES } from "commons/consts";
import { getTermsAndConditionVersion } from "utils/config.util";

const FooterComponent = () => (
  <div className={styles.footerContent}>
    <div>
      <a
        href={`${ROUTES.POLICIES_ROUTE}${ROUTES.TERMS_AND_CONDITIONS_ROUTE}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>{" "}
      -{" "}
      <a
        href={`${ROUTES.POLICIES_ROUTE}${ROUTES.PRIVACY_POLICY_ROUTE}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </div>
    <div>Version: {getTermsAndConditionVersion()}</div>
  </div>
);
export const Footer = React.memo(FooterComponent);
