import { all } from "redux-saga/effects";
import menu from "./menu/sagas";
import settings from "./settings/setting.saga";
import user from "./user/user.saga";
import notification from "./notification/notification.saga";
import kyc1 from "./kyc/step1/kyc1.saga";
import kyc2 from "./kyc/step2/kyc2.saga";
import kyc3 from "./kyc/step3/kyc3.saga";

export default function* rootSaga() {
  yield all([user(), menu(), settings(), kyc1(), kyc2(), kyc3(), notification()]);
}
