import { Avatar, List } from "antd";
import React from "react";
import styles from "./styles.module.scss";
import { datetimeUtil } from "utils";
import { withEnhanceNotification } from "HOCs/withEnhanceNotification";
import { NOTIFICATION_TYPE } from "commons/consts";
import countryList from "assets/country.json";
const getMessage = (data) => {
  if (data.message == null) return data.message;
  let formattedMessage = data.message;
  //Removing unwanted characters in notification message ("[]", ",")
  formattedMessage = formattedMessage.replace(/\[|]|\"/g, "");

  //** Update content parse data country */
  if (data.notificationType === NOTIFICATION_TYPE.SELLER_CONFIRM_PROFORMA_INVOICE) {
    formattedMessage = formattedMessage.replace(/\([^()]*\)/g, (code) => {
      const formatText = code.substring(code.lastIndexOf("(") + 1, code.lastIndexOf(")"));
      const selectedNationality = countryList.find((c) => c.alpha2Code === formatText).name;
      return selectedNationality;
    });
  }
  return formattedMessage;
};

const _NotificationItem = React.memo(({ data, onClick, renderDesc }) => {
  return (
    <List.Item.Meta
      className="dtc-cursor-pointer mr-3 dtc-bg-transparent"
      avatar={
        <Avatar
          icon={<i className="fe fe-shopping-cart" />}
          style={{ color: "#eb2f96", background: "none" }}
        />
      }
      description={
        renderDesc ? (
          renderDesc(styles)
        ) : (
          <div className={styles["item"]} onClick={onClick}>
            <p className="m-0 font-weight-bolder font-size-12" title={data.message}>
              {getMessage(data)}
            </p>
            <time className="font-size-10">{datetimeUtil.fromNow(data.createdDate)}</time>
          </div>
        )
      }
    />
  );
});

export const NotificationItem = withEnhanceNotification(_NotificationItem);
