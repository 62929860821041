import React from "react";
import { Layout, message } from "antd";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import { TopBar, SubBar, MenuLeft, Footer } from "components";
const AuthLayout = ({
  children,
  settings: {
    menuLayoutType = "left",
    isContentNoMaxWidth,
    isAppMaxWidth,
    isGrayBackground,
    isSquaredBorders,
    isCardShadow,
    isBorderless,
    isTopbarFixed,
    isGrayTopbar
  }
}) => {
  const walletStatus = useSelector((state) => state?.user?.walletStatus);
  React.useEffect(() => {
    if (walletStatus === "IN_PROGRESS") {
      message.destroy();
      message.warning(
        "Your wallet is being created, please contact customer service for more information",
        0
      );
    }
    if (walletStatus === "CANCELLED") {
      message.destroy();
      message.warning("There was an error in wallet creation, please contact customer service", 0);
    }
  }, [walletStatus]);
  return (
    <Layout
      className={classNames({
        air__layout__contentNoMaxWidth: isContentNoMaxWidth,
        air__layout__appMaxWidth: isAppMaxWidth,
        air__layout__grayBackground: isGrayBackground,
        air__layout__squaredBorders: isSquaredBorders,
        air__layout__cardsShadow: isCardShadow,
        air__layout__borderless: isBorderless
      })}
    >
      {/* <Sidebar />
          <SupportChat /> */}
      {menuLayoutType === "left" && <MenuLeft />}
      <Layout>
        <Layout.Header
          className={classNames("air__layout__header", {
            air__layout__fixedHeader: isTopbarFixed,
            air__layout__headerGray: isGrayTopbar
          })}
        >
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: "100%", position: "relative" }}>
          <SubBar />
          <section className="pl-3 pr-3 pt-1">{children}</section>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps)(AuthLayout);
