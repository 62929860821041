import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import { DATETIME_FORMAT } from "commons/consts";
import { getDateFormat } from "./config.util";
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const datetimeUtil = {
  formatLocalDatetime: (value, timezone, format = DATETIME_FORMAT) => {
    return moment.tz(moment.utc(value, format), timezone).format(format);
  },
  formatDate: (value) => {
    return moment(value, DATETIME_FORMAT).format(getDateFormat());
  },
  formatDateTime: (value, format = DATETIME_FORMAT) => {
    if (!dayjs(value, DATETIME_FORMAT).isValid()) {
      return value;
    }

    return dayjs(value, DATETIME_FORMAT).format(format);
  },
  getTimezonesForCountry: (alpha2Code) => {
    if (alpha2Code === undefined) {
      return [];
    }
    return moment.tz.zonesForCountry(alpha2Code);
  },

  minutesToTime: (minutes = 0, getTimeString = false) => {
    let h = (minutes / 60) | 0,
      m = minutes % 60 | 0;
    if (getTimeString) {
      const hString = h < 10 ? `0${h}` : h;
      const mString = m < 10 ? `0${m}` : m;
      return `${hString}:${mString}`;
    }
    return {
      h: h,
      m: m
    };
  },

  isBetweenTwoDate: (current, start, end) => {
    if (moment(start).isSameOrBefore(current) && moment(current).isBefore(end)) {
      return true;
    }
    return false;
  },

  sortByTime: (fieldName = undefined) => (value1, value2) => {
    if (fieldName === undefined) {
      return moment(value1) - moment(value2);
    } else {
      return moment(value1[fieldName]) - moment(value2[fieldName]);
    }
  },
  sortByTimeAscending: (fieldName = undefined) => (value1, value2) => {
    if (fieldName === undefined) {
      return moment(value2) - moment(value1);
    } else {
      return moment(value2[fieldName]) - moment(value1[fieldName]);
    }
  },
  fromNow: (value) => {
    return dayjs.utc(value).fromNow();
  }
};
