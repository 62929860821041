import {
  NOT_FOUND_ERROR_ROUTE,
  UNEXPECTED_ERROR_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  FEE_SCHEDULE_ROUTE,
  POLICIES_ROUTE,
  KYC_WAITING_ROUTE,
  EMAIL_UNLOCK_USER_ROUTE,
  UNLOCK_USER_ROUTE,
  ON_BOARD_ROUTE,
  KYC_PROCESS_ROUTE,
  HOME_ROUTE,
  ADMIN_USER_MANAGEMENT_ROUTE,
  USER_TAB_PROFILE_INFO_ROUTE,
  USER_TAB_COMPANY_INFO_ROUTE,
  USER_TAB_OWNER_INFO_ROUTE,
  USER_TAB_BANK_INFO_ROUTE,
  USER_TAB_BUSINESS_DETAILS_ROUTE,
  USER_TAB_SETTINGS_ROUTE,
  WALLET_ROUTE,
  SERVICE_REQUEST_ROUTE,
  SERVICE_REQUEST_DETAIL,
  SHIPMENT_DETAILS_ROUTE,
  SHIPMENT_ROUTE,
  DOCUMENTS_MANAGEMENT_DETAIL_ROUTE,
  NOTIFICATION,
  WITHDRAW_FUND_ROUTE,
  ACCOUNT_SUMMARY_ROUTE,
  ADD_FUNDS_ROUTE
} from "./shared-paths.const";
export const ERROR_LAYOUT_ROUTES = Object.freeze([NOT_FOUND_ERROR_ROUTE, UNEXPECTED_ERROR_ROUTE]);
export const PUBLIC_ROUTES = Object.freeze([
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  FEE_SCHEDULE_ROUTE,
  POLICIES_ROUTE,
  KYC_WAITING_ROUTE,
  EMAIL_UNLOCK_USER_ROUTE,
  UNLOCK_USER_ROUTE
]);
export const NO_LAYOUT_ROUTES = Object.freeze([ON_BOARD_ROUTE, KYC_PROCESS_ROUTE]);
export const PRIVATE_ROUTES = Object.freeze([
  HOME_ROUTE,
  ADMIN_USER_MANAGEMENT_ROUTE,
  USER_TAB_PROFILE_INFO_ROUTE,
  USER_TAB_COMPANY_INFO_ROUTE,
  USER_TAB_OWNER_INFO_ROUTE,
  USER_TAB_BANK_INFO_ROUTE,
  USER_TAB_BUSINESS_DETAILS_ROUTE,
  USER_TAB_SETTINGS_ROUTE,
  WALLET_ROUTE,
  SERVICE_REQUEST_ROUTE,
  SERVICE_REQUEST_DETAIL,
  SHIPMENT_DETAILS_ROUTE,
  SHIPMENT_ROUTE,
  DOCUMENTS_MANAGEMENT_DETAIL_ROUTE,
  NOTIFICATION,
  WITHDRAW_FUND_ROUTE,
  ACCOUNT_SUMMARY_ROUTE,
  ADD_FUNDS_ROUTE
]);
export const LAYOUT_POLICIES_ROUTES = [
  `${POLICIES_ROUTE}${TERMS_AND_CONDITIONS_ROUTE}`,
  `${POLICIES_ROUTE}${FEE_SCHEDULE_ROUTE}`,
  `${POLICIES_ROUTE}${PRIVACY_POLICY_ROUTE}`
];
