import * as _ERR_MSG from "./errors.const";
import * as _REGEX from "./regex.const";
import * as _MESSAGES from "./messages.const";
import * as _API_PATHS from "./api-paths.const";
import * as _LOCALSTORAGE from "./localstorage.const";
import * as _ROUTES from "./routes";

export const ROUTES = _ROUTES;
export const ERR_MSG = _ERR_MSG;
export const REGEX = _REGEX;
export const MESSAGES = _MESSAGES;
export const API_PATHS = _API_PATHS;
export const LOCALSTORAGE = _LOCALSTORAGE;
export * from "./api-error-code.const";
