import { backendAPI } from "utils/httpAPI.util";
import { getAccessToken } from "utils";
import { API_PATHS } from "commons/consts";
import isString from "lodash/isString";

/*
 * OWNER INFO
 */

export const getOwnerInfo = async () => {
  const accessToken = await getAccessToken();
  const addresses = await backendAPI.get(accessToken)(API_PATHS.OWNER_INFO);
  return addresses;
};

export const createOwnerInfo = async (values) => {
  const accessToken = await getAccessToken();
  const result = await backendAPI.post(accessToken)(API_PATHS.OWNER_INFO, values);
  return result;
};

export const updateOwnerInfo = async (id, values) => {
  const accessToken = await getAccessToken();
  await backendAPI.put(accessToken)(`${API_PATHS.OWNER_INFO}/${id}`, values);
};

export const deleteOwnerInfo = async (id) => {
  const accessToken = await getAccessToken();
  await backendAPI.delete(accessToken)(`${API_PATHS.OWNER_INFO}/${id}`);
};

export const uploadOwnerDocuments = async (ownerId, fileList) => {
  const accessToken = await getAccessToken();
  const uploadPromises = fileList.map(({ file, type }) => {
    if (isString(file) || file === undefined) {
      return null;
    }
    const formData = new FormData();
    formData.append("file", file);
    const promise = backendAPI.postFile(accessToken)(
      `${API_PATHS.OWNER_INFO}/${ownerId}/documents`,
      formData,
      {
        type
      }
    );
    return promise;
  });
  const result = await Promise.all(uploadPromises);
  return result;
};

export const deleteOwnerDocuments = async (id, ownerId) => {
  const accessToken = await getAccessToken();
  await backendAPI.delete(accessToken)(`${API_PATHS.OWNER_INFO}/${ownerId}/documents/${id}`);
};

export const getOwnerDocumentTypes = async (countryCode) => {
  const accessToken = await getAccessToken();
  const result = await backendAPI.get(accessToken)(
    `${API_PATHS.OWNER_INFO}/documentTypes/${countryCode}`
  );
  return result;
};
