import { all, put, takeEvery, call, select } from "redux-saga/effects";
import * as USER_ACTIONS from "./user.duck";
import { message, notification } from "antd";
import { APIError } from "commons/types";
import { getCompanyInfo } from "services/company.service";

import { history } from "index";
import { selectBrowserFingerprint } from "redux/settings/settings.duck";
import * as USER_DUCK from "./user.duck";
import * as NOTIFICATION_DUCKS from "redux/notification/notification.duck";
import { check2FAStatus, getCurrentAccount, login, logout, register } from "services";
import { ROUTES, MESSAGES } from "commons/consts";
const { setStateAction } = USER_DUCK;

export function* SAVE_CREDENTIALS({ payload }) {
  yield handleNormalLogin(payload);
}

export function* LOGIN({ payload }) {
  const { values, onError, on2FAEnabled } = payload;
  const { username, password } = values;
  try {
    yield put(setStateAction({ loading: true }));
    const fingerprintHash = yield select(selectBrowserFingerprint);
    const { is_locked, tfa_check, tfa_config } = yield check2FAStatus({
      username,
      password,
      browserId: fingerprintHash
    });

    if (tfa_check === false) {
      yield handleNormalLogin(values);
    } else {
      const { is_valid } = tfa_config;

      if (is_locked) {
        message.error("Your account has been locked, please try again in 1 hour");
      } else {
        if (is_valid) {
          yield handleNormalLogin(values);
        } else {
          on2FAEnabled && on2FAEnabled({ credentials: values, config: tfa_config });
        }
      }
    }
  } catch (error) {
    if (error instanceof APIError) {
      onError(error.errors);
      return;
    }
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

export function* LOGOUT({ payload }) {
  yield call(logout);
  yield put(
    setStateAction({
      id: "",
      country: "",
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      username: "",
      companyName: "",
      authorized: false,
      loading: false
    })
  );
  if (payload) {
    const { callback } = payload;
    callback();
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    yield put(setStateAction({ loading: true }));
    const user = yield getCurrentAccount();
    const company = yield getCompanyInfo();
    if (user) {
      const fingerprintHash = yield select(selectBrowserFingerprint);

      yield put(setStateAction({ loading: true }));
      yield put({
        type: USER_DUCK.SET_STATE,
        payload: {
          ...user,
          browserFingerprint: fingerprintHash,
          company: company,
          authorized: true
        }
      });
      yield put({ type: NOTIFICATION_DUCKS.INIT });
    } else {
      yield put({
        type: USER_ACTIONS.SET_STATE,
        payload: {
          ...user,
          authorized: false
        }
      });
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

export function* REGISTER({ payload }) {
  const { values, onError } = payload;
  try {
    yield put(setStateAction({ loading: true }));
    yield register(values);
    yield history.push(ROUTES.REGISTER_SUCCESS_ROUTE);
  } catch (error) {
    if (error instanceof APIError) {
      onError(error.errors);
      return;
    }
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

function* handleNormalLogin(values) {
  const isOk = yield login(values);
  if (isOk) {
    yield put({ type: USER_DUCK.LOAD_CURRENT_ACCOUNT });
    notification.success({
      message: MESSAGES.LOGIN_SUCCESS_TITLE,
      description: MESSAGES.getLoginSuccessMessage()
    });
  }
}

export default function* rootSaga() {
  yield all([
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    takeEvery(USER_ACTIONS.LOGOUT, LOGOUT),
    takeEvery(USER_ACTIONS.LOGIN, LOGIN),
    takeEvery(USER_DUCK.SAVE_CREDENTIALS, SAVE_CREDENTIALS),
    takeEvery(USER_ACTIONS.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(USER_ACTIONS.REGISTER, REGISTER)
  ]);
}
