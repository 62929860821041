import { all, takeLatest, put, select } from "redux-saga/effects";
import * as KYC2_DUCK from "./kyc2.duck";
import { getStep2, saveStep2 } from "services/kyc.service";
import { deleteOwnerDocuments } from "services";

const setStateAction = (payload) => {
  return {
    type: KYC2_DUCK.SET_STATE,
    payload: payload
  };
};

function* GET_STEP2() {
  try {
    yield put(setStateAction({ loading: true }));
    const values = yield getStep2();
    if (values.length) {
      yield put(setStateAction({ ownerInfo: values }));
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

function* SAVE_STEP2({ payload }) {
  const { values, onNext } = payload;
  try {
    yield put(setStateAction({ loading: true }));
    yield saveStep2(values);
    const updatedValues = yield getStep2();
    if (updatedValues.length) {
      yield put(setStateAction({ ownerInfo: updatedValues }));
    }
    onNext && onNext();
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

function* DELETE_DOCUMENT({ payload }) {
  try {
    const { fileIds, ownerId } = payload;
    let promises = [];
    if (Array.isArray(fileIds) && fileIds.length) {
      fileIds.forEach((id) => {
        promises.push(deleteOwnerDocuments(id, ownerId));
      });
      yield Promise.all(promises);
      let ownerInfo = yield select(KYC2_DUCK.selectOwnerInfo);
      let targetOwnerIndex = ownerInfo.findIndex((owner) => owner.id === ownerId);
      if (targetOwnerIndex > -1) {
        let newOwnerInfo = [...ownerInfo];
        newOwnerInfo[targetOwnerIndex] = {
          ...newOwnerInfo[targetOwnerIndex],
          documents: newOwnerInfo[targetOwnerIndex].documents.filter(
            (doc) => !fileIds.includes(doc.id)
          )
        };
        yield put(
          setStateAction({
            ownerInfo: newOwnerInfo
          })
        );
      }
    }
  } catch (error) {
    throw error;
  }
}

export default function* rootSaga() {
  yield all([takeLatest(KYC2_DUCK.GET_STEP2, GET_STEP2)]);
  yield all([takeLatest(KYC2_DUCK.SAVE_STEP2, SAVE_STEP2)]);
  yield all([takeLatest(KYC2_DUCK.DELETE_DOCUMENTS, DELETE_DOCUMENT)]);
}
