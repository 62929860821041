import { ROUTES } from "commons/consts";
import React from "react";
import { useRouteMatch } from "react-router";
import styles from "./public-layout.module.scss";

export const PublicLayout = React.memo(({ children }) => {
  const isPoliciesRouter = useRouteMatch({
    path: ROUTES.POLICIES_ROUTE,
    exact: false
  });
  return (
    <>
      {isPoliciesRouter ? (
        <div className="row no-gutters vh-100">
          <div style={{ minWidth: "100%" }}>{children}</div>
        </div>
      ) : (
        <section id={styles["public-layout"]}>
          <div className="row no-gutters vh-100">
            <div className="col-md-12 col-lg-6 px-0">
              <div id={styles.left} />
            </div>
            <div id={styles.right} className="col-md-12 col-lg-6 justify-content-center">
              <div className="container">
                <img
                  id={styles.logo}
                  className="ml-2 mb-3 mt-md-5 mt-1"
                  src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  alt="logo"
                />
                {children}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});
