import * as ERR_MSG from "./errors.const";

export const API_ERROR_CODES = Object.freeze({
  USER_EXISTS: "user.exist.true",
  EMAIL_EXISTS: "user.email.exist.true",
  LOGIN_INVALID: "login.invalid",
  INACTIVE_USER: "user.status.inactive",
  CURRENT_PASSWORD_NOT_VALID: "user.currentPassword.not.valid",
  USER_DISABLED: "user.disable.true"
});

export const API_ERRORS = Object.freeze({
  [API_ERROR_CODES.USER_EXISTS]: ERR_MSG.USERNAME_ALREADY_USED_ERR,
  [API_ERROR_CODES.EMAIL_EXISTS]: ERR_MSG.EMAIL_IS_ALREADY_USED_ERR,
  [API_ERROR_CODES.LOGIN_INVALID]: ERR_MSG.WRONG_USERNAME_OR_PW_ERR,
  [API_ERROR_CODES.INACTIVE_USER]: ERR_MSG.INACTIVE_USER,
  [API_ERROR_CODES.CURRENT_PASSWORD_NOT_VALID]: ERR_MSG.CUR_PW_IS_NOT_VALID,
  [API_ERROR_CODES.USER_DISABLED]: ERR_MSG.LOGIN_WRONG_OVER_3_TIMES_ERR
});
