import { backendAPI } from "utils/httpAPI.util";
import { getAccessToken } from "utils";
import { API_PATHS } from "commons/consts";

export const getNotificationList = async (page = 0, size = 5) => {
  const accessToken = await getAccessToken();
  const result = await backendAPI.get(accessToken)(API_PATHS.NOTIFICATIONS, {
    page,
    size,
    sort: "createdDate,desc"
  });
  return result;
};

export const setNotificationsRead = async () => {
  const accessToken = await getAccessToken();
  await backendAPI.put(accessToken)(API_PATHS.NOTIFICATIONS_READ);
};
