import { Loader, MenuDataManager } from "components/layout";
import { ConnectedRouter } from "connected-react-router";
import { Layout } from "layouts/main.layout";
import NotFoundPage from "pages/system/404/404.page";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import Switch from "react-router-transition-switch";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Route } from "react-router-dom";
import { ROUTES } from "commons/consts";

const mapStateToProps = ({ settings }) => ({ settings });

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation }
    } = this.props;
    return (
      <ConnectedRouter history={history}>
        <MenuDataManager />
        <Layout>
          <Suspense fallback={<Loader />}>
            <Switch
              render={(props) => {
                const {
                  children,
                  location: { pathname }
                } = props;
                return (
                  <SwitchTransition>
                    <CSSTransition
                      key={pathname}
                      classNames={routerAnimation}
                      timeout={routerAnimation === "none" ? 0 : 300}
                    >
                      {children}
                    </CSSTransition>
                  </SwitchTransition>
                );
              }}
            >
              {routes.map(({ path, Component, exact }) => {
                return (
                  <Route path={path} key={path} exact={exact}>
                    <Component />
                  </Route>
                );
              })}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </Layout>
      </ConnectedRouter>
    );
  }
}

export default Router;

const loadable = (loader) => React.lazy(loader);

const publicRoutes = [
  {
    path: ROUTES.LOGIN_ROUTE,
    Component: loadable(() => import("pages/login/login.page")),
    exact: true
  },
  {
    path: ROUTES.REGISTER_ROUTE,
    Component: loadable(() => import("pages/public/register/register.page")),
    exact: true
  },
  {
    path: ROUTES.REGISTER_SUCCESS_ROUTE,
    Component: loadable(() => import("pages/public/register-success/register-success.page")),
    exact: true
  },
  {
    path: ROUTES.EMAIL_CONFIRMATION,
    Component: loadable(() => import("pages/public/email-confirmation/email-confirmation.page")),
    exact: true
  },
  {
    path: ROUTES.FORGOT_PASSWORD_ROUTE,
    Component: loadable(() => import("pages/public/forgot-password/forgot-password.page")),
    exact: true
  },
  {
    path: ROUTES.FORGOT_PASSWORD_SUCCESS_ROUTE,
    Component: loadable(() =>
      import("pages/public/forgot-password-success/forgot-password-success.page")
    ),
    exact: true
  },
  {
    path: ROUTES.RESET_PASSWORD_ROUTE,
    Component: loadable(() => import("pages/public/reset-password/reset-password.page")),
    exact: true
  },
  {
    path: ROUTES.ON_BOARD_ROUTE,
    Component: loadable(() => import("pages/public/on-board/on-board.page")),
    exact: true
  },
  {
    path: ROUTES.KYC_WAITING_ROUTE,
    Component: loadable(() => import("pages/public/KYC-waiting/kyc-waiting.page")),
    exact: true
  },
  {
    path: ROUTES.KYC_PROCESS_ROUTE,
    Component: loadable(() => import("pages/public/KYC-process/kyc-process.page")),
    exact: true
  },
  {
    path: ROUTES.EMAIL_UNLOCK_USER_ROUTE,
    Component: loadable(() => import("pages/public/email-unlock-user/email-unlock-user.page")),
    exact: false
  },
  {
    path: ROUTES.UNLOCK_USER_ROUTE,
    Component: loadable(() => import("pages/public/unlock-user/unlock-user.page")),
    exact: false
  },
  {
    path: `${ROUTES.POLICIES_ROUTE}/:term`,
    Component: loadable(() => import("pages/public/policies")),
    exact: false
  }
];

const privateRoute = [
  {
    path: ROUTES.SERVICE_REQUEST_ROUTE,
    Component: loadable(() => import("pages/service-request/service-request.page")),
    exact: true
  },
  {
    path: ROUTES.HOME_ROUTE,
    Component: loadable(() => import("pages/home/home.page")),
    exact: true
  },
  {
    path: ROUTES.SERVICE_REQUEST_DETAIL,
    Component: loadable(() => import("pages/service-request-detail/service-request-detail.page")),
    exact: true
  },
  {
    path: `${ROUTES.PROFILE_ROUTE}/:tabName`,
    Component: loadable(() => import("pages/profile/profile.page")),
    exact: false
  },
  {
    path: ROUTES.SHIPMENT_DETAILS_ROUTE,
    Component: loadable(() => import("pages/shipment-details/shipment-details.page")),
    exact: true
  },
  {
    path: ROUTES.SHIPMENT_ROUTE,
    Component: loadable(() => import("pages/shipment/shipment.page")),
    exact: true
  },
  {
    path: ROUTES.DOCUMENTS_MANAGEMENT_DETAIL_ROUTE,
    Component: loadable(() =>
      import("pages/shipment-doc-mgt-details/shipment-doc-mgt-details.page")
    ),
    exact: true
  },
  {
    path: ROUTES.WALLET_ROUTE,
    Component: loadable(() => import("pages/wallet/wallet.page")),
    exact: true
  },
  {
    path: ROUTES.WITHDRAW_FUND_ROUTE,
    Component: loadable(() => import("pages/withdraw-fund/withdraw-fund.page")),
    exact: true
  },
  {
    path: ROUTES.ADD_FUNDS_ROUTE,
    Component: loadable(() => import("pages/add-funds/add-funds.page")),
    exact: true
  },
  // {
  //   path: ROUTES.ACCOUNT_SUMMARY_ROUTE,
  //   Component: loadable(() => import("pages/account-summary/account-summary.page")),
  //   exact: true
  // },

  // {
  //   path: ROUTES.ADD_FUNDS_ROUTE,
  //   Component: loadable(() => import("pages/add-funds/add-funds.page")),
  //   exact: true
  // },
  {
    path: ROUTES.NOTIFICATION,
    Component: loadable(() => import("pages/notification/notification.page")),
    exact: true
  }
];

const routes = [...publicRoutes, ...privateRoute];
