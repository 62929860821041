import React from "react";
import { useHistory } from "react-router-dom";
import { asyncErrorHandlerWrapper } from "utils/error-handler.util";
import { NOTIFICATION_TYPE, ROUTES } from "commons/consts";

export const withEnhanceNotification = (NotificationItemComp) => {
  return React.memo(({ data }) => {
    const history = useHistory();
    const { notificationType, subjectId } = data;

    const redirectWithNewState = (targetRoute) => {
      if (targetRoute.length) {
        history.push(targetRoute, { value: new Date().getUTCMilliseconds() });
      }
    };

    const handleNavigateToAction = () => {
      let targetRoute = "";
      asyncErrorHandlerWrapper(async () => {
        targetRoute = await getNavigateRoute(notificationType, subjectId);
        redirectWithNewState(targetRoute);
      });
    };

    return <NotificationItemComp data={data} onClick={handleNavigateToAction} />;
  });
};

const getNavigateRoute = async (notificationType, subjectId, callback) => {
  let targetRoute = "";

  switch (notificationType) {
    case NOTIFICATION_TYPE.OTP_CODE_WHATS_APP_MESSAGE:
    case NOTIFICATION_TYPE.TFA_WHATS_APP_MESSAGE:
    case NOTIFICATION_TYPE.WITHDRAWAL_REQUEST:
    case NOTIFICATION_TYPE.NEW_QUOTE_REQUEST: {
      targetRoute = `${ROUTES.SERVICE_REQUEST_DETAIL}?id=${subjectId}&edit=true`;
      break;
    }
    case NOTIFICATION_TYPE.SHIPMENT_CREATED:
    case NOTIFICATION_TYPE.CHANGE_PICKUP_DATE_QUOTE:
    case NOTIFICATION_TYPE.SHIPMENT_UPDATE_TRACKING:
    case NOTIFICATION_TYPE.SHIPMENT_UPDATE_STATUS: {
      targetRoute = `${ROUTES.SHIPMENT_DETAILS_ROUTE}?id=${subjectId}`;
      break;
    }
    case NOTIFICATION_TYPE.SHIPMENT_COMPLETED:
    case NOTIFICATION_TYPE.QUOTE_UPLOAD_DOCUMENT:
    case NOTIFICATION_TYPE.SHIPMENT_CANCEL:
    default: {
      break;
    }
  }

  return targetRoute;
};
