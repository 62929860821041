export const SET_STATE = "@@DTC/KYC2/SET_STATE";
export const GET_STEP2 = "@@DTC/KYC2/GET_STEP2";
export const SAVE_STEP2 = "@@DTC/KYC2/SAVE_STEP2";
export const DELETE_DOCUMENTS = "@@DTC/KYC2/SAVE_STEP2/DELETE_DOCUMENTS";

const initialState = {
  loading: true,
  ownerInfo: [{}]
};

export default function KYC2Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const selectLoadingState = (state) => state.kyc2.loading;
export const selectOwnerInfo = (state) => state.kyc2.ownerInfo;
