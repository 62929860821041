import { backendAPI } from "utils/httpAPI.util";
import { getAccessToken } from "utils";
import { API_PATHS } from "commons/consts";

export const check2FAStatus = async ({ browserId, password, username }) => {
  const status = await backendAPI.post()(API_PATHS.CHECK_2FA_STATUS, {
    browserId,
    password,
    username
  });
  return status;
};

export const checkValidationCode = async ({ browserId, code, username, type }) => {
  const status = await backendAPI.post()(API_PATHS.CHECK_VALIDATION_CODE, {
    browserId,
    code,
    username,
    type
  });
  return status;
};

export const forgetBrowser = async ({ browserId, code, type }) => {
  const accessToken = await getAccessToken();
  const status = await backendAPI.post(accessToken)(`${API_PATHS.FORGET_BROWSER}`, {
    browserId,
    code,
    type
  });
  return status;
};

export const sendValidationCode = async ({ browserId, username, type }) => {
  backendAPI.post()(`${API_PATHS.SEND_VALIDATION_CODE}`, {
    browserId,
    username,
    type
  });
};

export const sendOTPCode = async (browserId) => {
  const accessToken = await getAccessToken();
  backendAPI.post(accessToken)(`${API_PATHS.SEND_VALIDATION_CODE}`, {
    browserId
  });
};

export const update2FASettings = async (settings) => {
  const accessToken = await getAccessToken();
  backendAPI.put(accessToken)(`${API_PATHS.UPDATE_USER_INFO}/tfa`, settings);
};

export const getGoogleAuthenticator = async () => {
  const accessToken = await getAccessToken();
  const result = backendAPI.get(accessToken)(`${API_PATHS.GET_GOOGLE_AUTHENTICATOR_QRCODE}`);
  return result;
};

export const validateGoogleAuthenticator = async (code) => {
  const accessToken = await getAccessToken();
  const result = backendAPI.put(accessToken)(`${API_PATHS.GET_GOOGLE_AUTHENTICATOR_QRCODE}`, code);
  return result;
};
