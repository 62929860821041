import { USER_TABS_NAME } from "commons/consts/general.const";

export const LOGIN_ROUTE = "/login";
export const NOT_FOUND_ERROR_ROUTE = "/404";
export const UNEXPECTED_ERROR_ROUTE = "/500";
export const ON_BOARD_ROUTE = "/on-board";
export const HOME_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const REGISTER_SUCCESS_ROUTE = "/register-success";
export const EMAIL_CONFIRMATION = "/email-verification";
export const EMAIL_UNLOCK_USER_ROUTE = "/email-unlock-user";
export const UNLOCK_USER_ROUTE = "/unlock-user";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const FORGOT_PASSWORD_SUCCESS_ROUTE = "/forgot-password-success";
export const TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
export const FEE_SCHEDULE_ROUTE = "/feeschedule";
export const POLICIES_ROUTE = "/policies";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const KYC_WAITING_ROUTE = "/waiting-kyc";
export const KYC_PROCESS_ROUTE = "/complete-kyc";
export const SERVICE_REQUEST_ROUTE = "/service-request";
export const SERVICE_REQUEST_DETAIL = "/service-request/service-request-details";
export const WALLET_ROUTE = "/wallet";
export const ACCOUNT_SUMMARY_ROUTE = "/account-summary";
export const WITHDRAW_FUND_ROUTE = "/withdraw-fund";
export const ADD_FUNDS_ROUTE = "/add-funds";
export const PROFILE_ROUTE = "/user/profile";
export const USER_TAB_PROFILE_INFO_ROUTE = `/user/profile/${USER_TABS_NAME.profileInfo}`;
export const USER_TAB_COMPANY_INFO_ROUTE = `/user/profile/${USER_TABS_NAME.companyInfo}`;
export const USER_TAB_OWNER_INFO_ROUTE = `/user/profile/${USER_TABS_NAME.ownerInfo}`;
export const USER_TAB_BANK_INFO_ROUTE = `/user/profile/${USER_TABS_NAME.bankDetails}`;
export const USER_TAB_BUSINESS_DETAILS_ROUTE = `/user/profile/${USER_TABS_NAME.businessDetails}`;
export const USER_TAB_SETTINGS_ROUTE = `/user/profile/${USER_TABS_NAME.settings}`;
export const ADMIN_USER_MANAGEMENT_ROUTE = "/user-management";
export const SHIPMENT_DETAILS_ROUTE = "/shipment/shipment-details";
export const SHIPMENT_ROUTE = "/shipment";
export const DOCUMENTS_MANAGEMENT_DETAIL_ROUTE =
  "/shipment/:shipmentNumber/document-management/:docId";
export const NOTIFICATION = "/notifications";
