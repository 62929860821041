import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import menu from "./menu/reducers";
import settings from "./settings/settings.duck";
import user from "./user/user.duck";
import notification from "./notification/notification.duck";
import kyc1 from "./kyc/step1/kyc1.duck";
import kyc2 from "./kyc/step2/kyc2.duck";
import kyc3 from "./kyc/step3/kyc3.duck";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    kyc1,
    kyc2,
    kyc3,
    notification
  });
export default reducers;
