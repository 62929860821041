import range from "lodash/range";
export const COMMON_FIELDS = {
  createdDate: "createdDate",
  requestOn: "requestOn"
};
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_PICKER_FORMAT = "DD-MM-YYYY";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm A";
export const HH_MM_FORMAT = "HH:mm";
export const DAYS_IN_MINUTES = range(0, 1440, 30);
export const SORT_ORDERS = {
  DESC: "desc",
  ASC: "asc"
};
export const WEEK_DAYS = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
};
export const USER_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED",
  IN_PROGRESS: "IN_PROGRESS"
};
export const USER_TABS_NAME = {
  profileInfo: "profile-info",
  companyInfo: "company-info",
  ownerInfo: "owner-info",
  bankDetails: "bank-details",
  documents: "uploaded-documents",
  security: "add-credit-security",
  settings: "settings",
  businessDetails: "business-details"
};
export const TWO_FACTOR_AUTH_TYPES = {
  DISABLED: "TWOFA_DISABLED",
  PER_30_DAYS: "PER_30_DAYS",
  EVERY_LOGIN: "EVERY_LOGIN",
  WHATSAPP_PER_30_DAYS: "TWOFA_WHATSAPP_EVERY_30_DAYS",
  WHATSAPP_EVERY_LOGIN: "TWOFA_WHATSAPP_EVERY_LOGIN",
  EMAIL_PER_30_DAYS: "TWOFA_EMAIL_EVERY_30_DAYS",
  EMAIL_EVERY_LOGIN: "TWOFA_EMAIL_EVERY_LOGIN",
  SMS_PER_30_DAYS: "TWOFA_SMS_EVERY_30_DAYS",
  SMS_EVERY_LOGIN: "TWOFA_SMS_EVERY_LOGIN",
  GA_PER_30_DAYS: "TWOFA_GA_EVERY_30_DAYS",
  GA_EVERY_LOGIN: "TWOFA_GA_EVERY_LOGIN"
};
export const getTwoFAType = (method, setting) => {
  if (setting === TWO_FACTOR_AUTH_TYPES.PER_30_DAYS) {
    if (method === "EMAIL") return TWO_FACTOR_AUTH_TYPES.EMAIL_PER_30_DAYS;
    else if (method === "SMS") return TWO_FACTOR_AUTH_TYPES.SMS_PER_30_DAYS;
    else if (method === "GA") return TWO_FACTOR_AUTH_TYPES.GA_PER_30_DAYS;
    else return TWO_FACTOR_AUTH_TYPES.WHATSAPP_PER_30_DAYS;
  } else {
    if (method === "EMAIL") return TWO_FACTOR_AUTH_TYPES.EMAIL_EVERY_LOGIN;
    else if (method === "SMS") return TWO_FACTOR_AUTH_TYPES.SMS_EVERY_LOGIN;
    else if (method === "GA") return TWO_FACTOR_AUTH_TYPES.GA_EVERY_LOGIN;
    else return TWO_FACTOR_AUTH_TYPES.WHATSAPP_EVERY_LOGIN;
  }
};
export const NOTIFICATION_CHANNELS = {
  EMAIL: "EMAIL",
  WHATSAPP: "WHATSAPP",
  WEB: "WEB",
  SMS: "SMS"
};
export const THREE_STEPS_SECURITY_STATUS = {
  SUCCESS: "SUCCESS",
  INVALID: "INVALID",
  OTP_EXPIRED: "OTP_EXPIRED",
  OTP_LOCKED: "OTP_LOCKED",
  PASSCODE_LOCKED: "PASSCODE_LOCKED"
};
export const DOC_NAME_TYPES = {
  SERIAL_NUMBER: "SERIAL_NUMBER",
  COMMERCIAL_INVOICE: "COMMERCIAL_INVOICE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  LABEL_IMAGE: "LABEL_IMAGE",
  SHIPMENT_RECEIPT: "SHIPMENT_RECEIPT"
};
export const DOC_CODE_LABELS = {
  [DOC_NAME_TYPES.PURCHASE_ORDER]: "pro-forma-invoice",
  [DOC_NAME_TYPES.LABEL_IMAGE]: "label-image",
  [DOC_NAME_TYPES.COMMERCIAL_INVOICE]: "commercial-invoice",
  [DOC_NAME_TYPES.SHIPMENT_RECEIPT]: "shipment-receipt",
  [DOC_NAME_TYPES.SERIAL_NUMBER]: "serial-number"
};
export const DOC_NAME_LABELS = {
  [DOC_NAME_TYPES.SERIAL_NUMBER]: "Serial Number",
  [DOC_NAME_TYPES.PURCHASE_ORDER]: "Purchase Order",
  [DOC_NAME_TYPES.LABEL_IMAGE]: "Label Image",
  [DOC_NAME_TYPES.COMMERCIAL_INVOICE]: "Commercial Invoice",
  [DOC_NAME_TYPES.SHIPMENT_RECEIPT]: "Shipment Receipt"
};
export const MARKETPLACE_KEY = {
  "8Corners": "8CO",
  Extrabeaute: "EXT",
  Distichain: "DIS"
};
export const NOTIFICATION_TYPE = {
  OTP_CODE_WHATS_APP_MESSAGE: "OTP_CODE_WHATS_APP_MESSAGE",
  TFA_WHATS_APP_MESSAGE: "TFA_WHATS_APP_MESSAGE",
  WITHDRAWAL_REQUEST: "WITHDRAWAL_REQUEST",
  NEW_QUOTE_REQUEST: "NEW_QUOTE_REQUEST",
  SHIPMENT_CREATED: "SHIPMENT_CREATED",
  SHIPMENT_COMPLETED: "SHIPMENT_COMPLETED",
  QUOTE_UPLOAD_DOCUMENT: "QUOTE_UPLOAD_DOCUMENT",
  SHIPMENT_CANCEL: "SHIPMENT_CANCEL",
  CHANGE_PICKUP_DATE_QUOTE: "CHANGE_PICKUP_DATE_QUOTE",
  SHIPMENT_UPDATE_TRACKING: "SHIPMENT_UPDATE_TRACKING",
  SHIPMENT_UPDATE_STATUS: "SHIPMENT_UPDATE_STATUS"
};

export const MESUREMENT_UNITS = {
  SI: "SI",
  SU: "SU"
};

export const MESUREMENT_UNIT_LABELS = {
  [MESUREMENT_UNITS.SI]: "Imperial – Lb/inch",
  [MESUREMENT_UNITS.SU]: "Metric – Kg/cm"
};

export const WEIGHT_UNITS = {
  KG: "kg",
  LB: "lb"
};

export const DIMENSION_UNITS = {
  CM: "cm",
  IN: "in"
};

export const PAYMENT_METHODS = Object.freeze({
  MARKETPLACE_SERVICES: "MARKETPLACE_SERVICES"
});

const PAYMENT_METHODS_LABELS = Object.freeze({
  [PAYMENT_METHODS.MARKETPLACE_SERVICES]: "From my wallet"
});
export const getPaymentMethodLabels = () => PAYMENT_METHODS_LABELS;

export const PROFORMA_INVOICE_TABLE_LABELS = Object.freeze({
  slno: "SL NO",
  uom: "UOM",
  hscode: "HSCODE",
  productDescription: "Description",
  quantity: "Qty",
  unitPrice: "Unit Price",
  taxPercentage: "(%)",
  taxValue: "(value)",
  lineTotalExcTax: "Line Total (Excl. Tax)",
  lineTotalIncTax: "Line Total (Incl. Tax)"
});

export const CREDITS = Object.freeze({
  EXTERNAL_PAYMENT: {
    type: "EXTERNAL_PAYMENT",
    label: "External Payment"
  },
  A_SEVEN_DAYS: {
    type: "A_SEVEN_DAYS",
    label: "7 days"
  },
  B_FOURTEEN_DAYS: {
    type: "B_FOURTEEN_DAYS",
    label: "14 days"
  },
  C_TWENTY_ONE_DAYS: {
    type: "C_TWENTY_ONE_DAYS",
    label: "21 days"
  },
  D_TWENTY_EIGHT_DAYS: {
    type: "D_TWENTY_EIGHT_DAYS",
    label: "28 days"
  },
  E_THIRTY_FIVE_DAYS: {
    type: "E_THIRTY_FIVE_DAYS",
    label: "35 days"
  },
  ESCROW_ON_DELIVERY: {
    type: "ESCROW_ON_DELIVERY",
    label: "Escrow on Delivery"
  }
});

export const parseCreditTerm = (credit) => {
  return CREDITS[credit] ? CREDITS[credit].label : "";
};
