import NProgress from "nprogress";
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { selectCurrentUser } from "redux/user/user.duck";
import AuthLayout from "./auth/auth.layout";
import { PublicLayout } from "./public/public.layout";
import { Loader } from "components";
import { ROUTES, USER_STATUSES } from "commons/consts";
import { LAYOUT_POLICIES_ROUTES } from "commons/consts/system/routes";
import { getCompanyFullName } from "utils/config.util";
import { modifyVars } from "less";
const Layouts = {
  public: PublicLayout,
  private: AuthLayout,
  noLayout: null
};

export const Layout = React.memo(({ children }) => {
  const [themeLoading, setThemeLoading] = React.useState(true);
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const { pathname } = location;
  const isPrivateRoute = useRouteMatch({
    path: ROUTES.PRIVATE_ROUTES,
    exact: true
  });
  const isNoLayoutRoute = useRouteMatch({
    path: ROUTES.NO_LAYOUT_ROUTES,
    exact: true
  });

  useEffect(() => {
    modifyVars({
      "@primary-color": "#005691",
      "@btn-primary-bg": "#005691",
      "@info-color": "#0887c9",
      "@success-color": "#46be8a",
      "@error-color": "#fb434a",
      "@highlight-color": "#fb434a",
      "@warning-color": "#f39834",
      "@normal-color": "#e4e9f0",
      "@input-hover-border-color": "#005691",
      "@input-border-color": "#005691",
      "@input-icon-color": "#005691"
    }).then(() =>
      setTimeout(() => {
        setThemeLoading(false);
      }, 1000)
    );
  }, []);

  useEffect(() => {
    // NProgress Management
    NProgress.start();
    setTimeout(() => {
      NProgress.done();
    }, 300);
  }, [location]);

  const getLayout = () => {
    if (isPrivateRoute !== null) {
      return "private";
    }
    if (isNoLayoutRoute !== null) {
      return "no-layout";
    }
    return "public";
  };

  const BootstrappedLayout = () => {
    const Container = Layouts[getLayout(pathname)];
    const isUserAuthorized = user.authorized;
    const isUserLoading = user.loading;
    const isUserActive = user.status === USER_STATUSES.ACTIVE;
    const isCompanyActive = user.companyStatus === USER_STATUSES.ACTIVE;
    const isCompanyWaitingForVerification = user.companyStatus === USER_STATUSES.IN_PROGRESS;
    const isPrivateLayout = getLayout() === "private";
    const isErrorPage = getLayout() === "error";
    const isNotFoundPage = getLayout() === "notfound";
    const isNoLayoutPage = getLayout() === "no-layout";
    const userCompany = user.company;

    const boostrapChildren = Container ? <Container>{children}</Container> : children;

    const bootstrapByExactRoute = (routeName) => {
      if (pathname === routeName) {
        return boostrapChildren;
      }
    };

    if (themeLoading) {
      return <Loader />;
    }

    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && isUserAuthorized === false && isPrivateLayout) {
      return <Loader />;
    }
    // Error page or page not found
    if (isErrorPage || isNotFoundPage) {
      return children;
    }

    // redirect to update timezone
    if (isUserAuthorized && isUserActive && isCompanyActive && userCompany.timezone === undefined) {
      if (pathname === ROUTES.ON_BOARD_ROUTE) {
        return boostrapChildren;
      }
      return <Redirect to={ROUTES.ON_BOARD_ROUTE} />;
    }

    // redirect to KYC waiting page if company is still waiting for verification
    if (isUserAuthorized && isUserActive && isCompanyWaitingForVerification) {
      if (pathname === ROUTES.KYC_WAITING_ROUTE) {
        return boostrapChildren;
      }
      return <Redirect to={ROUTES.KYC_WAITING_ROUTE} />;
    }

    // redirect to KYC page if company not verified && user is admin
    if (isUserAuthorized && isUserActive && !isCompanyActive) {
      if (pathname === ROUTES.KYC_PROCESS_ROUTE) {
        return boostrapChildren;
      }
      return <Redirect to={ROUTES.KYC_PROCESS_ROUTE} />;
    }

    // redirect to KYC waiting page if company is still waiting for verification
    if (isUserAuthorized && isUserActive && isCompanyWaitingForVerification) {
      if (pathname === ROUTES.KYC_WAITING_ROUTE) {
        return boostrapChildren;
      }
      return <Redirect to={ROUTES.KYC_WAITING_ROUTE} />;
    }

    if (isUserAuthorized === false && (isPrivateLayout || isNoLayoutPage)) {
      return <Redirect to="/login" />;
    }

    if (isUserAuthorized && isPrivateLayout === false) {
      const routeName = LAYOUT_POLICIES_ROUTES.find((item) => item === pathname);
      if (routeName) {
        return bootstrapByExactRoute(routeName);
      }
      return <Redirect to="/" />;
    }

    // in other case render previously set layout
    return boostrapChildren;
  };

  return (
    <Fragment>
      <Helmet titleTemplate={`${getCompanyFullName()} - %s`} />
      {BootstrappedLayout()}
    </Fragment>
  );
});
