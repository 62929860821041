export const getHotjarSV = () => {
  return window._env_?.REACT_APP_HOTJAR_SV ?? "";
};
export const getHotjarID = () => {
  return window._env_?.REACT_APP_HOTJAR_ID ?? "";
};
export const getCurrency = () => {
  return window._env_?.REACT_APP_DEFAULT_CURRENCY_TYPE ?? "";
};
export const getAPIURL = () => {
  return window._env_?.REACT_APP_API_ENDPOINT ?? "";
};
export const getCompanyFullName = () => {
  return window._env_?.REACT_APP_FULL_COMPANY_NAME ?? "";
};
export const getTermsAndConditionVersion = () => {
  return window._env_?.APPLICATION_VERSION ?? "";
};
export const getDateFormat = () => {
  return window._env_?.DATE_FORMAT ?? "DD-MM-YYYY";
};
