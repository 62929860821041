export const SET_STATE = "@@DTC/KYC1/SET_STATE";
export const SAVE_STEP1 = "@@DTC/KYC1/SAVE_STEP1";
export const GET_STEP1 = "@@DTC/KYC1/GET_STEP1";

const initialState = {
  logoUrl: undefined,
  loading: true,
  addresses: [
    {
      addressLine1: undefined,
      country: undefined,
      id: undefined,
      phone: undefined,
      state: undefined
    }
  ],
  registrationNumber: "",
  certificateRegistration: "",
  certificateRegistrationIssueDate: "",
  certificateRegistrationValidDate: "",
  establishmentStatus: "",
  establishmentYear: "",
  kybVerified: false,
  name: undefined,
  jurisdictionOfIncorporation: undefined,
  jurisdictionOfIncorporationDate: undefined,
  tradeLicenseIssueDate: null,
  tradeLicenseNumber: "",
  tradeLicenseValidDate: null,
  workingStartTime: undefined,
  workingEndTime: undefined,
  workingDays: [],
  incorporationDate: undefined,
  registrationExpiryDate: undefined
};

export default function KYC1Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const selectLoadingState = (state) => state.kyc1.loading;
export const selectCompanyInfo = (state) => {
  return {
    id: state.kyc1.id,
    name: state.kyc1.name,
    country: state.kyc1.country,
    registrationNumber: state.kyc1.registrationNumber,
    jurisdictionOfIncorporation: state.kyc1.jurisdictionOfIncorporation,
    jurisdictionOfIncorporationDate: state.kyc1.jurisdictionOfIncorporationDate,
    logoUrl: state.kyc1.logoUrl,
    workingStartTime: state.kyc1.workingStartTime,
    workingEndTime: state.kyc1.workingEndTime,
    workingDays: state.kyc1.workingDays,
    timezone: state.kyc1.timezone,
    officeNumber: state.kyc1.officeNumber,
    officeNumberPrefix: state.kyc1.officeNumberPrefix,
    certificationDocument: state.kyc1.certificationDocument,
    incorporationDate: state.kyc1.incorporationDate,
    registrationExpiryDate: state.kyc1.registrationExpiryDate
  };
};
export const selectCompanyAddress = (state) => state.kyc1.addresses;
export const selectTradeLicenseDetails = (state) => {
  const {
    establishmentStatus,
    establishmentYear,
    tradeLicenseIssueDate,
    tradeLicenseNumber,
    tradeLicenseValidDate,
    certificateRegistration,
    certificateRegistrationIssueDate,
    certificateRegistrationValidDate
  } = state.kyc1;
  return {
    establishmentStatus,
    establishmentYear,
    tradeLicenseIssueDate,
    tradeLicenseNumber,
    tradeLicenseValidDate,
    certificateRegistration,
    certificateRegistrationIssueDate,
    certificateRegistrationValidDate
  };
};
