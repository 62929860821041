import { getCompanyFullName } from "utils/config.util";

export const getLoginSuccessMessage = () =>
  `You have successfully logged in to ${getCompanyFullName()}!`;
export const LOGIN_SUCCESS_TITLE = "Logged In";
export const CHANGE_PASSWORD_SUCCESS = "Change Password Success";
export const RESET_PASSWORD_SUCCESS = "Reset Password Success";
export const PHONE_VERIFICATION_CODE_SENT =
  "We've sent you a verification code to the above phone number.";
export const SEND_VERIFICATION_CODE = "Send Verification Code";
export const PHONE_VERIFY_CODE = "Verify";
export const VERIFY_PHONE_NOW = "Please verify your phone number";
export const VERIFY_PHONE_SUCCESSFUL = "Verify successful";
export const UPDATE_SUCCESSFUL = "Update Successful";
export const VERIFY_PHONE_TO_USE_THIS_FEATURE =
  "Please verify your phone number to use this feature";
